import React, { Dispatch, SetStateAction } from "react"

import { Modal } from "components/Modal"
import { Accordion } from "components/Accordion"
import { Button } from "components/Button"
import { BadgeColorsEnum } from "constants/"

import classes from "./HelpModal.module.scss"

type Props = {
  isActive: boolean
  setIsActive: Dispatch<SetStateAction<boolean>>
}

const HelpModal: React.FC<Props> = ({ isActive, setIsActive }) => {
  return (
    <Modal
      badgeColor={BadgeColorsEnum.Success}
      title="Help & getting started"
      isActive={isActive}
      setIsActive={setIsActive}
      style={{ height: "auto" }}
    >
      <div className={classes.container}>
        <div className={classes.divider}></div>
        <Accordion label="Introduction" defaultIsActive>
          Seedhodler is a tool that uses{" "}
          <a
            href="https://en.wikipedia.org/wiki/Shamir%27s_Secret_Sharing"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Shamir's secret sharing
          </a>{" "}
          to generate, split, and rejoin master seeds for cryptocurrency wallets. A master seed is a
          secret string of words that is used to generate the private keys for your cryptocurrency
          addresses. It is important to keep your master seed safe and secure, as anyone who has access
          to it can potentially access and steal your cryptocurrencies.
          <div className={classes.margin} />
          Using Seedhodler, you can split your master seed into multiple pieces, called "shares," and
          store them in different locations. This way, you can ensure that your master seed is secure
          even if one of the shares is lost or stolen. When you want to access your cryptocurrencies, you
          can use Seedhodler to rejoin the shares and retrieve your master seed.
        </Accordion>
        <Accordion label="Generating a Master Seed and Shares" defaultIsActive>
          <ul className={classes.list}>
            <li>
              To generate a new master seed, click on the "Generate New Seed" button. The seed will be
              generated from scratch by your computer. If you prefer to manually enter a master seed that
              has been generated before, click on the "Enter Existing Seed" button and enter the seed in
              the designated field.
            </li>
            <li>
              If you do not want to rely on your computer to generate random numbers, you can activate
              the advanced toolset to manually enter any entropy that is generated by an external device,
              such as dice or cards.
            </li>
            <li>
              Determine how many seed shares you want to create and how many shares are required to
              reconstruct the seed. For example, if you choose to create 5 shares and require 3 shares to
              reconstruct the seed, you can distribute the 5 shares to different locations and only need
              3 of them to reconstruct the seed.
            </li>
            <li>
              Click the "Split" button to generate the shares. You will be presented with your first
              share, and you can use the arrow button to slide through all of them.
            </li>
            <li>
              Use the export button to print as many forms for your seed shares as you need on your
              printer to fill out your shares. The shares are not printed in the forms because otherwise
              this info may remain in the memory of your printer which is putting your master seed at
              risk.
            </li>
            <li>
              Follow the process to write down all your seeds and perform a check to ensure that you have
              written down all shares. This will take some time, so do not rush through it.
            </li>
          </ul>
        </Accordion>
        <Accordion label="Reconstructing a Master Seed" defaultIsActive>
          <ul>
            <li>
              To reconstruct your master seed using the shares, click on the "Restore" button. You will
              be prompted to enter any of the shares.
            </li>
            <li>
              After you have entered the first share, you will be presented with the information on how
              many shares are missing to restore your master seed.
            </li>
            <li>
              After you have entered enough valid shares, you will see the master seed which you can then
              use to import into your (hardware wallet).
            </li>
          </ul>
        </Accordion>
        <Accordion label="Tips and Best Practices" defaultIsActive>
          <ul>
            <li>
              Keep your master seed private and offline to prevent unauthorized access to your
              cryptocurrencies.
            </li>
            <li>
              Use an offline computer to generate or split your master seed and ensure that it is not
              connected to the internet during the setup process or afterwards when there is a storage
              medium connected. Consider using an offline version of Seedhodler and run on a live USB OS.
            </li>
            <li>
              Consider destroying the original master seed after generating its shares, as this reduces
              the risk of a single point of failure in securing your assets. However, make sure to verify
              that all of your Shamir shares are working and stored in a safe location before doing so.
            </li>
            <li>
              Store your seed shares in multiple secure locations, such as a physical safe, secure
              storage device, or safe deposit box. You may also choose to share copies with trusted
              friends or family members, but make sure that these individuals do not know each other in a
              way that would allow them to collaborate and restore the master seed without your
              knowledge.
            </li>
          </ul>
        </Accordion>
        <div className={classes.buttonContainer}>
          <Button onClick={() => setIsActive(false)}>Done</Button>
        </div>
      </div>
    </Modal>
  )
}

export default HelpModal
